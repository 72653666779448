:root {
  --highlight-width: auto;
  --highlight-x-pos: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

html {
  scroll-behavior: smooth !important;
}

#form, #ambition, #projects, #mission, #faq {
  scroll-margin-top: 100px;
}
